.modal-conversation- {
  &light,
  &dark {
    position: relative;
    width: 100%;
    height: 90%;
    border-radius: 20px;
    padding: 20px;
    margin-top: 3px;

    .close-button-conversation {
      border: none;
      outline: none;
      cursor: pointer;
      position: absolute;
      top: 30px;
      left: 20px;
      &:active {
        border: none;
      }
    }

    .box-conversation {
      padding: 40px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      &-icon {
        margin-bottom: 80px;
      }

      div {
        text-align: center;
      }
    }
  }

  &light {
    background-color: #fff;
    color: black;
    .close-button-conversation {
      background: none;
      color: #555;
    }

    .box-conversation {
      div {
        h3 {
          color: #555;
        }
      }
    }
  }

  &dark {
    background-color: hsl(228, 28%, 20%);
    color: hsl(228, 34%, 66%);
    .close-button-conversation {
      background: none;
      color: hsl(228, 34%, 66%);
    }

    .box-conversation {
      div {
        h3 {
          color: hsl(228, 34%, 66%);
        }
      }
    }
  }
}

