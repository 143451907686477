@import '../styles/variables';

@mixin defaultButton {
    background-color: $lightBlueInsideOne;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.127);
    outline: none;
    font-size: 1rem;
    padding: 10px 20px;
    transition: all ease-in 0.3s;
    border: none ;
    border-radius: 5px;
    &:hover {
        border: none;
        background-color: rgb(0, 147, 197);
        transition: all ease-in 0.3s;
    }
}
