@import "../../assets/styles/mixins";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
.modal-cont-title- {
  width: 90%;

  &h1 {
    text-align: center;
  }
}

.singleView__ {
  &container__ {
    &light,
    &dark {
      position: relative;
      width: 55%;
      height: 90%;
      border-radius: 20px;
      padding: 40px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 1000px) {
        width: 70%;
      }

      @media screen and (max-width: 800px) {
        width: 80%;
      }
      @media screen and (max-width: 512px) {
        width: 95%;
      }
    
      @media screen and (min-height: 830px){
        

        height: 85%;
      }

      @media screen and (min-height: 850px){
        

        height: 80%;
      }

      @media screen and (min-height: 900px){
        

        height: 70%;
      }

      @media screen and (min-height: 1000px){
        

        height: 65%;
      }
    }

    &light {
      background-color: #fff;
    }
    &dark {
      background-color: hsl(228, 28%, 20%);
    }
  }

  &image_eric {
    width: 155px;
    margin-bottom: 15px;
  }

  &title {
    &__dark {
      color: #8b97c6;
    }
    &__light {
      color: black;
    }
  }

  &subtitle {

    &__dark,  &__light {
      @media screen and (max-height: 710px){
        font-size: .8rem;
      }
    }
    &__dark {
      color: #5ac3e2;
      
    }
    &__light {
      color: black;
     
    }
  }

  &cont_info {
    width: 80%;
 
    overflow-y: auto;
    display: flex;
    flex-direction: column;
   
    padding: 0 70px 0 70px;
    align-items: center;
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:active {
      background-color: #62d6f9;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #5ac3e2;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #62d6f9;
    }
    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #d5d0d0;
      transition: all ease 0.3s;
    }
  }
  &info {
    width: 100%;

    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
    border-bottom: 1px solid #8b97c6;

  }

  &conversation,
  &recomendation {
    flex-direction: column;
    align-items: flex-start;
    min-height: fit-content;

    &_span {
      text-indent: 2.2rem;
    }
  }
  &recomendation {
    height: auto;
    padding: 20px;
  }

  &conversation {
    word-wrap: break-word;
    padding: 20px;
    border: none;

    ul {
      padding: 0;
      margin: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      column-gap: 5px;
      list-style: none;

      li {
        width: 100%;
        margin: 5px 0;
        span {
          text-indent: 2.2rem;
        }
      }
    }
  }

  &cont_title {
    display: flex;
    align-items: center;

    span {
      font-size: 1.1rem;
    }

    @media screen and (max-height: 710px){
        

      span {
        font-size: .8rem;
      }
    }
  }


  &seeChartBtn__{
    &dark , &light{
      @include defaultButton;
      margin-top: 3rem;
    }
    &dark{
      color:  #f1fcff;
    }
    &light{
      color:  #fdffff;
    }
    
  }
}

.close-button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 30px;
  color: #555;
  &:active {
    border: none;
  }
}

.close-icon {
  font-weight: bold;
  font-size: 24px;
  color: black;
}

.main-icon {
  height: 150px;
  margin-bottom: 30px;
}

.close-button-dark {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 30px;
  color: #555;
  &:active {
    border: none;
  }
}

.close-icon-dark {
  font-weight: bold;
  font-size: 24px;
  color: black;
}



.chatBotScrollBar{

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: #62d6f9;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #5ac3e2;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #62d6f9;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #d5d0d0;
    transition: all ease 0.3s;
  }

}