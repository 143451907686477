.table__body {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.733);
  margin-bottom: 50px;
  color: black;
  border: 1px solid black;
}

.table__cell {
  padding: 20px 16px 20px 16px;
  text-align: center;
}

.border_left {
  border-radius: 10px 0px 0px 10px;
}

.border_right {
  border-radius: 0px 10px 10px 0px;
}

table,
th,
td {
  padding: 1rem;
  text-align: left;
}

td {
  padding: 20px;
  border-bottom: 1px solid rgb(158, 158, 158);
}

thead th {
  position: sticky;
  top: 0;
  left: 0;
  background-color: rgb(237, 237, 237);
}

table, th {
  border-collapse: collapse;
}

table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 10px 10px 10px 10px; /* Ajusta el radio de borde según lo desees */
}

.center-text{
  text-align: center;
}

.bold{
  font-weight: bold;
}