td {
  padding: 20px;
  border-bottom: 1px solid rgb(158, 158, 158);
  
}
  td:nth-child(1) 
  {
    width: 17%;
  }
  td:nth-child(2) {
    width: 10%;
  }
  td:nth-child(3) {
    width: 10%;
  }
  td:nth-child(4) {
    width: 17%;
  }
  td:nth-child(5) {
    width: 13%;
  }
  td:nth-child(6) {
    width: 15%;
  }

  
.status-positive {
/*   padding: 0.4rem 0;
  border-radius: 2rem;
  text-align: center; */
  background-color: #86e49e52;
/*   color: #006b21;
  font-weight: bold; */

}

.status-negative {
 /*  padding: 0.4rem 0;
  border-radius: 2rem;
  text-align: center; */
  background-color: #da799056;
 /*  color: #b30021;
  font-weight: bold; */
}

.status-medium {
 /*  padding: 0.4rem 0;
  border-radius: 2rem;
  text-align: center;
  color: #b37b0c; */
  background-color: #f4c8715d;
/*   font-weight: bold; */
}

tbody {
  color: black;
}

.center-text {
  text-align: center;
  margin: 0 auto;
}

tr:hover {
  background-color: rgb(242, 242, 242);
  cursor: pointer;
}
