.badWordsDropdown{
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
}
&::-webkit-scrollbar-thumb:active {
    background-color: #62d6f9;
}
&::-webkit-scrollbar-thumb:hover {
    background-color: #5ac3e2;
}
&::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #62d6f9;
}
&::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #d5d0d0;
    transition: all ease 0.3s;
}
}


.textLightToggle::placeholder {
  color: hsl(228, 12%, 44%)
}

.textDarkToggle::placeholder {
  color: hsl(228, 34%, 66%);
}



.defaultButton {
  background-color: #62d6f9;
  color: rgb(255, 255, 255);
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: all ease 0.3s;
  outline: none;
  // box-shadow: 2px 2px 5px #4b4f5067;
  &:hover {
    background-color: #5ac3e2;
  }
  &:active {
    background-color: #4e9cbf;
    outline: none;
  }
}


input {
  ::placeholder {
    font-size: .9rem;
    font-style: italic;
    text-align: center;
    color: #2d8d8e;
  }
}